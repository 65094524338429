import * as React from 'react';
import LoadingBar from '../blocks/codes/loadingBar';

// markup
const NotFoundPage = () => {
      return (
            <main>
                  <title>Loading...</title>
                  <LoadingBar />
            </main>
      );
};

export default NotFoundPage;
